
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Image,
  Table,
  Spinner,
  Modal,
  Form,
} from "react-bootstrap";
import { getStyles } from "./ViewPastOrder.style";
import Section from "../../Components/Section/Section";
import { FaAngleRight, FaArrowRight, FaRegClock, FaSort } from "react-icons/fa";
import Search from "../../Components/Search/Search";
import shopImage from "../../assets/images/shop-icon.png";
import Sort from "../../assets/images/sort.png";
import ProductImage from "../../assets/images/onion.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  cancelLineItem,
  fetchCancellationReasons,
  Search_Past_Orders,
  Search_Store_Orders,
  View_Past_Orders,
} from "../../Api";
import { base_url } from "../../Api/ApiConst";
import noProduct from "../../assets/images/no-product.png";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faDownload, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { INITIAL_TAB } from "../../Redux/User";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/Store";
import ShopIcon from "../../assets/images/default-shop.png";
import { Player } from "@lottiefiles/react-lottie-player";
import lottieEmptyCart from "../../assets/lotties/emptyCart.json";
import { toast } from "react-toastify";

interface Category {
  message: string;
  status: boolean;
  user_order_list?: {
    amount: string;
    order_details?: [];
  };
}
interface Product {
  PRDPrice: number;
  PRDWeight: number;
  unit_measure: string;
  current_price?: {
    discount_type?: 'amount' | 'percent';
    discount_val?: number;
  };
  // Add other product properties as needed
}

interface ProductItem {
  product: Product;
  quantity: number;
  status: string;
  note?: string; // for cancellation reason
}


const ViewPastOrder: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = getStyles(isMobile);
  const breadcrumbs: string[] = ["Home", "Store"];
  const handleSearch = (query: string) => {
    // Implement your search logic here
    console.log("Searching for:", query);
  };
  const [loading, setLoading] = useState<boolean>(false);

  const languageId: any = useSelector((state: RootState) => state.lan);

  // interface Category {
  //   id: string;
  //   quantity: string;
  //   price: string;
  //   product?:{
  //     product_image: number;
  //   };
  //   order_details:any
  // }
  const [sortBy, setSortBy] = useState<string>("");
  const [userToken, setUserToken] = useState<string | null>("");
  const [storeName, setStoreName] = useState<string | null>("");
  const [storeId, setStoreId] = useState<string | null>("");
  const [product, setProduct] = useState<any>({});
  const [products, setProducts] = useState<any>([]);
  const [vendorValue, setVendorValue] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<any>(1);
  // const [searchText, setSearchText] = useState("");
  // const [storeId, setStoreId] = useState<string | null>("");

  const [store, setStore] = useState<string>("");
  const [orderId, setOrderId] = useState<string | null>("");
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  const [showCancelItemModal, setShowCancelItemModal] = useState(false);
  const [cancelItemReason, setCancelItemReason] = useState("");
  const [cancelItemId, setCancelItemId] = useState<number | null>(null);
  const [reasonOptions, setReasonOptions] = useState<string[]>([]); // State to store reasons
  const [pastOrderData, setPastOrderData] = useState<any>(null); // State to store past order data
  const [otherCancelReason, setOtherCancelReason] = useState<string | null>(""); // State to store other reason input
  const handleSort = (criteria: string) => {
    // Implement your sorting logic here
    setSortBy(criteria);
  };

  const dispatch = useDispatch();

  const { id } = state;

  useEffect(() => {
    setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
    setOrderId(localStorage.getItem(STORAGE_CONSTANTS.ORDER_ID));
    setStoreId(localStorage.getItem(STORAGE_CONSTANTS.STORE_ID));
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (userToken) {
      Cart_Details();
    }
  }, [userToken]);
  useEffect(() => {
    if (userToken) {
      fetchOrders(); // Fetch orders on userToken change
    }
  }, [userToken]);
  const fetchOrders = async () => {
    try {
      setLoading(true);
      const response = await View_Past_Orders(id);
      if (response && response.status === 200) {
        setPastOrderData(response.data.cart_details.order); // Set the past order data
        setProducts(response.data.cart_details.order.order_list);
        setProduct(response.data.cart_details.order.order_details);
        setVendorValue(response.data.cart_details.order.store_order_calculation)
      } else {
        console.log("Error fetching Cart details");
      }
    } catch (error: any) {
      console.log("Error fetching Cart details", error);
    } finally {
      setLoading(false);
    }
  };
  // const fetchOrderDetails = async () => {
  //   try {
  //     const response = await Search_Past_Orders(id, storeId, currentPage);
  //     if (response && response.status === 200) {
  //       // const retrieveProductDetails=getProductDetails(response.data)
  //       console.log(response.data, "dataaaaaaaa=====>");
  //       setProduct(response.data.user_order_list[0]);
  //       setProducts(response.data.user_order_list[0].order_details);
  //       // setProduct(response.data.store_product_list)
  //     } else {
  //       console.log("Error fetching Past Order Details");
  //     }
  //   } catch (error) {
  //     console.log("Error fetching Past Order Details", error);
  //   }
  // };

  const getProductDetails = (productObject: any) => {
    const getExtraProductInfo = {};
    const getRequiredFields =
      productObject?.user_order_list?.order_details.find((item: any) => { });
  };

  const handleBack = () => {
    navigate("/profile");
    dispatch(INITIAL_TAB("Order"));
  };

  const Cart_Details = async () => {
    try {
      setLoading(true);
      const response = await View_Past_Orders(id);
      if (response && response.status === 200) {
        console.log(response.data, "hgggggggggggggggggggggggj");
        // setOrderId(response.data.cart_details.order.id);
        setProducts(response.data.cart_details.order.order_list);
        setProduct(response.data.cart_details.order.order_details);
        setVendorValue(response.data.cart_details.order.store_order_calculation);
        const cartDataItems: any =
          response.data?.cart_details?.order?.order_list;
        let pickupArray: any[] = [];

        // Object.keys(cartDataItems).map((key) => {
        //   const foundPickupOption = selectedPickupOptions.find(
        //     (ele) => ele?.id == key
        //   );
        //   const productsForStore = cartDataItems[key];
        //   let storePickupOptions: any;

        //   if (productsForStore?.length > 0) {
        //     storePickupOptions = getDeliveryOptions(
        //       productsForStore[0]?.product?.product_vendor_store?.store_type
        //     );
        //   }

        //   if (foundPickupOption?.id && foundPickupOption?.selectedOption) {
        //     pickupArray.push(foundPickupOption);
        //   } else if (storePickupOptions.length === 1) {
        //     const Obj = {
        //       id: key,
        //       selectedOption: storePickupOptions[0]?.value,
        //     };
        //     pickupArray.push(Obj);
        //   } else {
        //     const Obj = {
        //       id: key,
        //       selectedOption: "",
        //     };
        //     pickupArray.push(Obj);
        //   }
        // });
        // setSelectedPickupOptions(pickupArray);

        // setStores(response.data.store_list);
      } else {
        console.log("Error fetching Cart details");
      }
    } catch (error: any) {
      console.log("Error fetching Cart details", error);
      // setProducts("")
    } finally {
      setLoading(false);
    }
  };


  // const handleClick = (id: any) => {
  //   const state = {
  //     product_id: id
  //   };
  //   navigate('/view-product', { state: state });
  // }

  // console.log(userToken)
  const handleOpenCancelItemModal = async (itemId: number) => {
    console.log("handleOpenCancelItemModal called with itemId:", itemId); // Debug itemId
    setCancelItemId(itemId);
    try {
      const response = await fetchCancellationReasons();
      // console.log("Cancellation reasons response:", response);
      console.log("Cancellation reasons response:", JSON.stringify(response, null, 2));

      if (response.status) {
        setReasonOptions(response.cancel_reason_list || []);
      } else {
        toast.error('No cancellation reasons available');
      }
    } catch (error) {
      toast.error('Failed to fetch cancellation reasons');
    }
    setShowCancelItemModal(true);
  };
  useEffect(() => {
    console.log("cancelItemId updated:", cancelItemId); // Debug state update
  }, [cancelItemId]);

  const handleCancelItem = async () => {
    console.log("cancelItemId:", cancelItemId);
    console.log("orderId:", id);
    console.log("cancelItemReason:", cancelItemReason);
    console.log("otherCancelReason:", otherCancelReason);

    if (cancelItemId && id && cancelItemReason) {
      const payload = {
        cancel_reason: cancelItemReason,
        ...(cancelItemReason === "Other Reasons" && { other_reason: otherCancelReason }), // Add other_reason if condition is true
      };

      if (cancelItemReason === "Other Reason" && !otherCancelReason) {
        toast.error("Please provide your reason in the textarea.");
        return; // Exit if no other reason is provided
      }

      try {
        const response = await cancelLineItem(id, cancelItemId.toString(), cancelItemReason, otherCancelReason);
        console.log("Cancel item response:", response);

        if (response?.status) {
          toast.success('Item successfully canceled');
          setCancelItemReason("");
          setCancelItemId(null);
          setOtherCancelReason(""); // Reset the other reason
          setShowCancelItemModal(false);
          fetchOrders();
        } else {
          toast.error(response?.message || 'Failed to cancel item'); // Show message from the response
        }
      } catch (error: any) {
        const errorMessage = error?.response?.data?.message || 'Failed to cancel item'; // Get the message from error response
        toast.error(errorMessage); // Show message from catch block
      }

      setShowCancelItemModal(false);
    } else {
      toast.error('Please select a cancellation reason.');
    }
  };
  const getCancellationReason = (cancelVal: string): string | null => {
    try {
      // First, check if the value is a JSON-like string
      if (cancelVal.startsWith("{") && cancelVal.endsWith("}")) {
        // Format the string for valid JSON
        const formattedVal = cancelVal
          .replace(/'/g, '"') // Convert single quotes to double quotes
          .replace(/True/g, 'true') // Convert Python's True to JavaScript's true
          .replace(/False/g, 'false'); // Convert Python's False to JavaScript's false

        const parsedVal = JSON.parse(formattedVal);
        return parsedVal.reason; // Return the reason from parsed JSON
      } else if (cancelVal.startsWith("Other Reason:")) {
        // Handle the case where "Other Reason" is directly provided
        return cancelVal; // Return the reason directly if it's already a string
      } else {
        // Handle the case where the cancellation reason is a predefined string
        return cancelVal; // Return the predefined reason directly
      }
    } catch (error) {
      console.error("Error parsing cancel_val:", error);
      return null; // Return null or handle error accordingly
    }
  };

  // Usage example
  const cancellationReason = getCancellationReason(product.cancel_val);



  return (
    <>
      <Section className="viewOrder" style={styles.bg}>
        <Container>
          <Row>
            <Col>
              <div className="breadcrumbs">
                {breadcrumbs.map((crumb, index) => (
                  <React.Fragment key={index}>
                    <span
                      style={{
                        color: index === 0 ? "var(--primary-color)" : "#BEB8B8",
                        fontFamily: "var(--primary-font-medium)",
                        fontSize: 16,
                      }}
                    >
                      {crumb}
                    </span>
                    {index !== breadcrumbs.length - 1 && (
                      <FaArrowRight
                        className="mx-2"
                        style={{ color: "#BEB8B8" }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={1}></Col>
            <Col sm={11} className="px-5">
              <h2 style={styles.orderId}>
                {t("viewOrder.orderId")} : #{id}
              </h2>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section className="view-order" style={{ marginTop: -30 }}>
        <div style={styles.whiteWrap}>
          <Container>
            {!loading && Object.keys(products).length !== 0 && (<>
              <Row className="">
                <Col sm={1}>
                  <Button
                    variant="link"
                    style={{ padding: 0 }}
                    onClick={handleBack}
                  >
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      className="arrow-left"
                    />
                  </Button>
                </Col>

                <Col
                  sm={4}
                  className="align-items-end d-flex justify-content-start pr-3 mb-center"
                >
                  <h3 style={styles.viewOrderInfo}>
                    {t("orderList.status")} :{" "}
                    <span style={styles.info}>{product?.status}</span>
                  </h3>



                </Col>
                <Col sm={4} className="mb-center">
                  <h3 style={styles.viewOrderInfo}>
                    {t("viewOrder.orderDate")} :{" "}
                    <span style={styles.info}>
                      {String(product?.purchase_date).split("T")[0]}
                    </span>
                  </h3>
                </Col>
                <Col
                  sm={3}
                  className="align-items-end d-flex justify-content-center pr-3 mb-center"
                >
                  {product?.expected_delivery && (
                    <h3 style={styles.viewOrderInfo}>
                      {"Delivery Date"} :{" "}
                      <span style={styles.info}>
                        {String(product?.expected_delivery).split("T")[0]}
                      </span>
                    </h3>
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm={1}></Col>
                <Col sm={11}>
                  {product.status === "Cancelled" && (
                    <div>
                      <h3 style={styles.viewOrderInfo}>
                        Cancellation Reason:<span style={styles.info}> {cancellationReason}</span>
                      </h3>
                    </div>
                  )}
                </Col>
              </Row></>
            )}
            <div style={styles.orderTableWrap}>
              {loading && (
                <Row>
                  <Col className="d-flex align-items-center justify-content-center">
                    <Spinner animation="grow" variant="primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </Col>
                </Row>
              )}
              {!loading && Object.keys(products).length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h3 style={styles.noProduct}>
                    <Image
                      src={noProduct}
                      className="mx-2"
                      style={{ width: 32, height: 32 }}
                    />{" "}
                    {t("cart.emptyCart")}{" "}
                  </h3>
                </div>
              ) : (
                <>
                  {Object.keys(products).map((item, index) => (
                    <React.Fragment key={index}></React.Fragment>
                  ))}
                  <div className="table-responsive">
                  <Table
                    style={styles.orderTable}
                    className="productTable mb-0"
                  >
                    {!loading && Object.keys(products).length !== 0 && (
                      <thead>
                        <tr>
                          <th ></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th style={{ ...styles.th, textAlign: "right" }}>Status</th>
                          {/* <th style={{ ...styles.th, textAlign: "right" }}>
                            {t("userProfile.qty")}
                          </th> */}
                          <th></th>
                          <th style={{ ...styles.th, textAlign: "right" }}>
                            {/* {t("viewOrder.amount")} */}
                            Total
                          </th>


                        </tr>
                      </thead>
                    )}
                    <tbody>
                      {Object.keys(products).map((item, index) => {
                        const productItems: ProductItem[] = products[item]; // Type assertion for products[item]
                        const storeId = item; // Assuming 'item' is the store ID (e.g., '221', '378', etc.)
                        const storeDetails = vendorValue[storeId]; // vendorValue contains store_order_calculation

                        const subtotal = productItems.reduce((total: number, productItem: ProductItem) => {
                          return total + (productItem.product.PRDPrice * productItem.quantity);
                        }, 0);

                        const discount = productItems.reduce((totalDiscount: number, productItem: ProductItem) => {
                          const discountValue = productItem.product.current_price?.discount_type === 'amount'
                            ? productItem.product.current_price.discount_val! * productItem.quantity
                            : productItem.product.current_price?.discount_type === 'percent'
                              ? (productItem.product.PRDPrice * productItem.quantity * (productItem.product.current_price.discount_val! / 100))
                              : 0;
                          return totalDiscount + discountValue;
                        }, 0);

                        return (
                          <React.Fragment key={index}>
                            <tr style={styles.cartItem}>
                              <td colSpan={7} style={{ padding: 0 }}>
                              <div
                                  className="d-flex align-items-center justify-content-between my-1"
                                  style={styles.shopWrap}
                                >
                                  <div className="d-flex align-items-center justify-content-between">
                                    <Image
                                      src={
                                        products[item][0]?.product
                                          ?.product_vendor_store?.logo
                                          ? `${base_url}/${products[item][0]?.product?.product_vendor_store?.logo}`
                                          : ShopIcon
                                      }
                                      className="hide-xs"
                                      style={{ width: 44 }}
                                    />

                                    <div className="mt-2 mx-3">
                                      <p
                                        className="mb-0"
                                        style={styles.cartTitle}
                                      >
                                        {products[item][0]?.product
                                          ?.product_vendor_store?.language_data
                                          .length === 0
                                          ? products[item][0]?.product
                                            ?.product_vendor_store?.store_name
                                          : languageId === "ne"
                                            ? products[item][0]?.product
                                              ?.product_vendor_store
                                              ?.language_data[0]?.store_name
                                            : products[item][0]?.product
                                              ?.product_vendor_store?.store_name}
                                      </p>
                                      <div
                                        style={{ display: "flex", gap: "0.2rem" }}
                                      >
                                        <p style={styles.orderInfoo}>
                                          {t("viewOrder.storeAddress")}
                                        </p>{" "}
                                        <div
                                          style={{
                                            display: "flex",
                                            gap: "0.2rem",
                                          }}
                                        >
                                          <p style={styles.orderInfoo}>
                                            {
                                              products[item][0]?.product
                                                ?.product_vendor_store?.address
                                            }
                                          </p>
                                          ,
                                          <p style={styles.orderInfoo}>
                                            {
                                              products[item][0]?.product
                                                ?.product_vendor_store?.city
                                            }
                                          </p>
                                          ,
                                          <p style={styles.orderInfoo}>
                                            {
                                              products[item][0]?.product
                                                ?.product_vendor_store?.pincode
                                            }
                                          </p>
                                        </div>
                                      </div>
                                      
                                    </div>
                                 
                                  </div>
                                  <div className="method">
  {products[item][0]?.delivery_method === "DELIVERY"
    ? "Delivery"
    : products[item][0]?.delivery_method === "PICKED_UP"
    ? "Pick Up"
    : ""}
</div>
                                </div>
                              </td>
                            </tr>
                            {products[item].map(
                              (productItem: any, subIndex: any) => (
                                <tr key={subIndex} style={{ paddingTop: 30 }}>
                                  <td >
                                    {/* <div style={styles.dot}></div> */}
                                    {productItem.status === "New" ? (
                                      <Button
                                        variant="link"
                                        onClick={() => handleOpenCancelItemModal(productItem.product.id)} // Ensure productItem.id is valid
                                      >
                                        <FontAwesomeIcon icon={faTimes} color="red" />
                                      </Button>
                                    ) : (<></>)}

                                    <Modal
                                      show={showCancelItemModal}
                                      onHide={() => setShowCancelItemModal(false)}
                                      centered
                                      className="cancelOrderModal"
                                    >
                                      <Modal.Header closeButton>
                                        <Modal.Title>{t("cancelItem.cancelItem")} </Modal.Title>
                                      </Modal.Header>
                                      <Modal.Body>
                                        <Form>
                                          <Form.Group controlId="cancelItemReason">
                                            <Form.Label>{t("cancelItem.reasonForCancellation")} </Form.Label>
                                            <Form.Control
                                              as="select"
                                              value={cancelItemReason}
                                              onChange={(e) => setCancelItemReason(e.target.value)}
                                            >
                                              <option value="">{t("cancelItem.selectReason")} </option>
                                              {reasonOptions.map((reason, index) => (
                                                <option key={index} value={reason}>
                                                  {reason}
                                                </option>
                                              ))}
                                            </Form.Control>
                                          </Form.Group>
                                          {cancelItemReason === "Other Reason" && (
                                            <Form.Group controlId="otherReasonTextarea">
                                              {/* <Form.Label></Form.Label> */}
                                              <Form.Control
                                                as="textarea"
                                                className="mt-3"
                                                rows={3}
                                                placeholder={t("cancelItem.pleaseSpecifyYourReason")}
                                                value={otherCancelReason || ""} // Ensure it's not null
                                                onChange={(e) => setOtherCancelReason(e.target.value)}
                                              />
                                            </Form.Group>
                                          )}
                                        </Form>
                                      </Modal.Body>
                                      <Modal.Footer>
                                        <Button className="btnCancel" onClick={() => setShowCancelItemModal(false)}>
                                          {t("cancelItem.no")}
                                        </Button>
                                        <Button className="btnBlue" onClick={handleCancelItem}>
                                          {t("cancelItem.yes")}
                                        </Button>
                                      </Modal.Footer>
                                    </Modal>
                                  </td>
                                  <td>
                                    <div style={styles.orderImageWrap}>
                                      <Image
                                        src={
                                          base_url +
                                          productItem?.product?.product_image
                                        }
                                        style={styles.orderImage}
                                      />
                                    </div>
                                  </td>
                                  <td style={styles.orderInfo}>
                                    <p
                                      className="mb-0"
                                      style={{
                                        fontFamily: "var(--primary-font-medium)",
                                      }}
                                    >
                                      {productItem?.product?.language_data
                                        .length === 0
                                        ? productItem?.product?.product_name
                                        : languageId === "ne"
                                          ? productItem?.product?.language_data[0]
                                            ?.product_name
                                          : productItem?.product?.product_name}
                                    </p>
                                    <p
                                      className="mb-0"
                                      style={{
                                        fontFamily: "var(--primary-font-regular)",
                                        fontSize: 16,
                                      }}
                                    >
                                      {productItem.product.PRDWeight}{" "}
                                      {productItem.product.unit_measure} {" "}<br></br>

                                      {(productItem.product.current_price?.discount_type === 'amount' || productItem.product.current_price?.discount_type === 'percent') ? (
                                        <>           {productItem.price}
                                        </>) : <>{productItem.product.PRDPrice}</>}
                                      &#10005; {" "}
                                      {productItem.quantity} (Qty)<br></br>

                                    </p>

                                  </td>

                                  <td
                                    style={{
                                      ...styles.orderInfo,
                                      textAlign: "left",
                                      fontSize: 14,
                                      fontWeight: 500
                                    }}
                                  >
                                    {productItem.product.current_price?.discount_type === 'amount' ? (
                                      <span style={{ fontWeight: 600 }}>
                                        Product Specific Promo Code - ₹{productItem.product.current_price.discount_val}  OFF
                                        <br />
                                      </span>
                                    ) : productItem.product.current_price?.discount_type === 'percent' ? (
                                      <span style={{ fontWeight: 600 }}>
                                        Product Specific Promo Code - {productItem.product.current_price.discount_val}% OFF
                                        <br />
                                      </span>
                                    ) : null}


                                  </td>
                                  <td style={{
                                    ...styles.orderInfo,
                                    textAlign: "right",
                                    fontFamily: "var(--primary-font-semibold)"
                                  }}>
                                    <div className="lineItem">
                                      <div>
                                        {/* <span className="key">{t("cancelItem.status")}: </span> */}
                                        <span className="value" style={{ fontWeight: 600 }}>{productItem.status}</span></div>
                                      {productItem.status === "Cancelled" ? (<div><span className="key">{t("cancelItem.cancellationReason")} </span><span className="value">{productItem.note}</span></div>) : (
                                        <></>
                                      )}
                                    </div>

                                  </td>
                                  <td></td>
                                  {/* <td style={{
                                    ...styles.orderInfo,
                                    textAlign: "right",
                                    fontFamily: "var(--primary-font-medium)",
                                   
                                  }}>
                                    
                                   
                                  

                                  </td> */}
                                  <td
                                    style={{
                                      ...styles.orderInfo,
                                      textAlign: "right",
                                      fontFamily: "var(--primary-font-semibold)",
                                    }}
                                  >
                                    {(productItem.product.current_price?.discount_type === 'amount' || productItem.product.current_price?.discount_type === 'percent') ? (

                                      <span style={{
                                        textDecoration: "line-through",
                                        color: "#9f9f9f"
                                      }}>

                                        ₹{productItem.product.PRDPrice * productItem.quantity}&nbsp;&nbsp;
                                      </span>) : null}



                                    ₹{parseFloat((productItem.price * productItem.quantity).toFixed(2))}
                                    {/* {productItem.price * productItem.quantity}<br></br> */}
                                    {/* {(productItem.product.current_price?.discount_type === 'amount' || productItem.product.current_price?.discount_type === 'percent') ? (
                                      <span style={{ color: "red" }}>
                                        -{parseFloat((productItem.product.PRDPrice * productItem.quantity - productItem.price * productItem.quantity).toFixed(2))}
                                      </span>) : null} */}
                                  </td>

                                </tr>
                              )
                            )}



                            <tr style={{ borderTopWidth: 1, borderTopStyle: "solid", borderTopColor: "#d9d9d9" }}>
                              <td
                                colSpan={6}
                                style={{
                                  ...styles.orderInfo,
                                  textAlign: "right",
                                  fontSize: 14,
                                  fontWeight: 600,
                                  padding: 0,
                                  paddingBottom: 5,
                                  paddingTop: 5
                                }}
                              >
                                Vendor Specific Promo Code
                              </td>
                              <td
                                style={{
                                  ...styles.orderInfo,
                                  textAlign: "right",
                                  fontSize: 14,
                                  fontWeight: 600,
                                  padding: 0,
                                  paddingBottom: 5,
                                  paddingTop: 5
                                }}
                              >
                                ₹{Number(storeDetails.vendor_promo_code ?? 0).toFixed(2)}
                              </td>
                            </tr>
                            {discount > 0 && (
                              <tr>
                                <td colSpan={6} style={{
                                  ...styles.orderInfo,
                                  textAlign: "right",
                                  fontSize: 14,
                                  fontWeight: 600,
                                  padding: 0,
                                  paddingBottom: 5
                                }}>
                                  Discount
                                </td>
                                <td style={{
                                  ...styles.orderInfo,
                                  textAlign: "right",
                                  fontSize: 14,
                                  fontWeight: 600,
                                  padding: 0, color: "red",
                                  paddingBottom: 5
                                }}>
                                  -₹{discount.toFixed(2)}
                                </td>
                              </tr>
                            )}
                            <tr>
                              <td colSpan={6} style={{
                                ...styles.orderInfo,
                                textAlign: "right",
                                fontSize: 14,
                                fontWeight: 600,
                                padding: 0,
                                paddingBottom: 5
                              }}>
                                Shipping Charge
                              </td>
                              <td style={{
                                ...styles.orderInfo,
                                textAlign: "right",
                                fontSize: 14,
                                fontWeight: 600,
                                padding: 0,
                                paddingBottom: 5
                              }}>
                                ₹{storeDetails.shipping_charges.toFixed(2)}
                              </td>
                            </tr>

                            <tr style={{ borderBottom: 1, borderBottomStyle: "solid", borderBottomColor: "#d9d9d9", borderTopWidth: 1, borderTopStyle: "solid", borderTopColor: "#d9d9d9" }}>
                              <td colSpan={6} style={{
                                ...styles.orderInfo,
                                textAlign: "right",
                                fontSize: 14,
                                fontWeight: 900,
                                padding: 0,
                                paddingTop: 10,
                                paddingBottom: 10
                              }}>
                                SubTotal
                              </td>
                              <td style={{
                                ...styles.orderInfo,
                                textAlign: "right",
                                fontSize: 14,
                                fontWeight: 900,
                                padding: 0,
                                paddingTop: 5,
                                paddingBottom: 5
                              }}>
                                ₹{storeDetails.store_order_amount.toFixed(2)}

                              </td>
                            </tr>

                            {/* Final Total row (if needed) */}
                            <tr style={{ borderBottom: 2, borderBottomStyle: "solid", borderBottomColor: "#000" }}>
                              <td colSpan={6} style={{
                                ...styles.orderInfo,
                                textAlign: "right",
                                fontSize: 14,
                                fontWeight: 600,
                                padding: 0,
                                paddingBottom: 10
                              }}>
                                Total
                              </td>
                              <td style={{
                                ...styles.orderInfo,
                                textAlign: "right",
                                fontSize: 14,
                                fontWeight: 900,
                                padding: 0,
                                paddingBottom: 10
                              }}>
                               
                               ₹{storeDetails.total_store_order_amount.toFixed(2)}
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </Table>
                  </div>
                </>
              )}
            </div>
            
            {!loading && Object.keys(products).length !== 0 && (
              <>
              <hr style={{ borderTopColor: "#000", marginTop: 0 }} />
              <h2 style={{
                ...styles.orderInfo,
                fontSize: 20,
                fontWeight: 900
              }}>Bill Details</h2>
                  <hr style={{ borderBottomColor: "#000", marginTop: 0 }} />
              <div>
                <div >
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <span style={styles.value500}>
                      {t("viewOrder.mrpTotal")}
                    </span>
                    <span style={styles.value600}>₹ {product?.sub_total}</span>
                  </div>
                  
                  <div className="d-flex align-items-center justify-content-between">
                    <span style={styles.value500}>{t("viewOrder.tax")}</span>
                    <span style={styles.value400}>0</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <span style={styles.value500}>
                      {t("viewOrder.shipping")}
                    </span>
                    <span style={styles.value400}>₹ {product?.total_shipping_charges}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <span style={styles.value500}>
                      Discount ({product?.coupon})
                    </span>
                    <span style={styles.value400}>
                      ₹ {product?.discount ? parseFloat(product?.discount) : 0}
                    </span>
                  </div>
                </div>
                <hr style={{ borderTopColor: "#000" }} />
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <span style={styles.value600}>
                    {/* {t("viewOrder.amount")} */}Bill Total
                  </span>
                  <span style={styles.value600}>₹ {product?.amount}</span>
                </div>
                <Row className="mt-5">
                  <Col>
                    <h2 style={styles.label}>
                      {t("viewOrder.deliveryZipCode")}
                    </h2>
                    <div style={{ ...styles.value, textAlign: "left" }}>
                      {
                        <>
                          <span>{product?.shipping_data?.street_address}</span>,
                          <span>{product?.shipping_data?.state}</span>,
                          <span>{product?.shipping_data?.post_code}</span>
                        </>
                      }
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <h2 style={styles.label}>{"Mode of Payment"}</h2>
                    <div style={{ ...styles.value, textAlign: "left" }}>
                      {product?.shipping_data?.payment_method === "cod"
                        ? "Cash on Delivery"
                        : product?.shipping_data?.payment_method ??
                        "Mode of Payment"}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <h2 style={styles.label}>
                      {t("viewOrder.productDeliveredNote")}
                    </h2>
                    <div style={{ ...styles.value, textAlign: "left" }}>
                      {product?.shipping_data?.order_note ??
                        t("viewOrder.deliveryNote")}
                    </div>
                  </Col>
                </Row>
                <Row className="my-5">
                  <Col>
                    <a
                      href={`${base_url}order/generate_invoice/${product?.id}/`}
                      className="btnDownloadInvoice"
                    >
                      <FontAwesomeIcon icon={faDownload} className="mx-2" />
                      {t("viewOrder.downloadOrderSummary")}
                    </a>
                  </Col>
                </Row>
              </div>
              </>
            )}
          </Container>
        </div>
      </Section>
    </>
  );
};

export default ViewPastOrder;
